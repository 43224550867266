import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
} from "@mui/material";
import { useState, useEffect } from "react";

const { BOOKING_TIME_ADD, HOUR_IN_MINUTES } = require('my-shared-utils');

const DateTimePicker = ({ date, setDate, hours, setHour, mins, setMin, duration, setDuration, showCal }) => {
  const [correctedDuration, setCorrectedDuration] = useState(duration);
  const [timePickerKey, setTimePickerKey] = useState(0);

  useEffect(() => {
    setCorrectedDuration(duration);
  }, [duration]);

  const handleHourChange = (e) => {
    setHour(e.target.value);
  };

  const handleMinChange = (e) => {
    setMin(e.target.value);
  };

  const handleDurChange = (newValue) => {
    if (!newValue) return;

    let h = newValue.getHours();
    let m = newValue.getMinutes();
    let newDuration = h + m / HOUR_IN_MINUTES;

    // Ensure the duration is at least 1/2 hour (30 minutes)
    if (newDuration < BOOKING_TIME_ADD.MINIMUM_EVENT_TIME_HOURS) {
      newDuration = BOOKING_TIME_ADD.MINIMUM_EVENT_TIME_HOURS;
      h = Math.floor(BOOKING_TIME_ADD.MINIMUM_EVENT_TIME_HOURS);
      m = (BOOKING_TIME_ADD.MINIMUM_EVENT_TIME_HOURS - h) * HOUR_IN_MINUTES;
    }

    // Create a new corrected Date object with the valid duration
    const newCorrectedTime = new Date(0, 0, 0, h, m);

    // Update state with the corrected duration and time
    setDuration(newDuration);
    setCorrectedDuration(newDuration);
    setTimePickerKey(prevKey => prevKey + 1); // Update the key to force re-render

    return newCorrectedTime;
  };

  const now = new Date();
  const maxDate = new Date(now);
  maxDate.setMonth(now.getMonth() + 3);
  const timeNums = new Array(24).fill("", 0, 24);
  const minNums = ["00", "15", "30", "45"];

  return (
    <Grid container direction="row" spacing={2}>
      {showCal ? (
        <>
          <Grid item xs={5}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  renderInput={(props) => <TextField {...props} />}
                  label="DatePicker"
                  value={new Date(date)}
                  maxDate={maxDate}
                  disablePast
                  onChange={(newDate) => {
                    setDate(newDate);
                  }}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel id="hourSelect">Hour</InputLabel>
              <Select
                labelId="hourSelect"
                id="hourSelect"
                label="Hour"
                value={hours}
                onChange={handleHourChange}
              >
                {timeNums.map((time, i) => (
                  <MenuItem key={i} value={i}>
                    {String(i).padStart(2, "0")}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel id="minSelect">Min</InputLabel>
              <Select
                labelId="minSelect"
                id="minSelect"
                label="Min"
                value={mins}
                onChange={handleMinChange}
              >
                {minNums.map((mins) => (
                  <MenuItem key={mins} value={mins}>
                    {mins}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </>
      ) : (
        <Grid item>
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                key={timePickerKey}
                ampm={false}
                renderInput={(props) => <TextField {...props} />}
                label="Duration Picker"
                minutesStep={15}
                skipDisabled={true}
                value={new Date(0, 0, 0, Math.floor(correctedDuration), (correctedDuration % 1) * HOUR_IN_MINUTES)}
                onChange={(newValue) => {
                  handleDurChange(newValue);
                }}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
      )}
    </Grid>
  );
};

export default DateTimePicker;
