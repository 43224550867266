import * as React from "react";
import {
  Grid,
  Box,
  Card,
  IconButton,
  Button,
  ButtonGroup,
  Modal,
  Typography,
  Stack,
} from "@mui/material/";
import { useState, useEffect, useContext } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useFetchData } from "../../hooks/useFetchData";
import { useInterval } from '../../hooks/useInterval';

import { SchedulatorContext } from "../../utils/Context";
import { BookingMode } from "../../utils/Context";

import { teaseOutDateElementsLocal, timeFixerLocal } from '../../utils/dateTimeHelpers';

let modalRefNum = "";

const checkDate = (dateOnly, previous) => {
  if (dateOnly !== previous) {
    previous = dateOnly;
    return (
      <Grid item xs={12} align="center" sx={{ bgcolor: "#046C9A80" }}>
        {dateOnly}
      </Grid>
    );
  } else {
    return null;
  }
};

const calendarMapper = (calendarDataResponse, confirmDelete, handleEditBooking, isEdit) => {
  const calendarRows = [];
  let currentDate = "";
  calendarDataResponse?.data?.message.forEach((booking, i) => {
    calendarRows.push(
      <Grid container key={i} minWidth={700} alignItems="center">
        {checkDate(teaseOutDateElementsLocal(booking.startTime), currentDate)}
        <Grid item xs={2}>
          <IconButton disabled={(isEdit && modalRefNum===booking.sdskNum) || !booking.system} onClick={() => handleEditBooking(booking)}>
            <EditIcon />
          </IconButton>
          <IconButton disabled={(isEdit && modalRefNum===booking.sdskNum)} onClick={() => {
            (booking.sdskNum) ? confirmDelete(booking.sdskNum) : confirmDelete(booking.refNum)
            }}>
            <DeleteIcon />
          </IconButton>
        </Grid>
        <Grid item xs={1} lg={2}>
          {booking.sdskNum}{booking.refNum}
        </Grid>
        <Grid item xs={1}>
          {booking.region}
        </Grid>
        <Grid item xs={2}>
          {timeFixerLocal(booking.startTime, booking.duration, booking.endTime)}
        </Grid>
        <Grid item xs={2}>
          {booking.name}
        </Grid>
        <Grid item xs={4} lg={3}>
          {booking.createdBy}
        </Grid>
      </Grid>
    );
    currentDate = teaseOutDateElementsLocal(booking.startTime);
  });
  return calendarRows;
};

const Schedule = ({
  isEdit,
  bookingResponse,
  updateBookingResponse,
  system,
}) => {
  const [calendarDataResponse, fetchCalendarData] = useFetchData();
  const [deleteBookingRes, requestDeleteBooking] = useFetchData();

  const { setBookingOb } = useContext(SchedulatorContext);
  const { setIsEdit } = useContext(BookingMode);

  const [deleteModalOpen, setDeleteModal] = useState(false);
  const handleDelModalOpen = () => setDeleteModal(true);
  const handleDelModalClose = () => setDeleteModal(false);

  const handleDeleteBooking = (refNum) => {
    requestDeleteBooking({ refNum, system }, "/delete-booking-v2");
    setIsEdit(false)
  };

  const confirmDelete = (refNum) => {
    modalRefNum = refNum;
    handleDelModalOpen();
  };

  const handleEditBooking = (booking) => {
    modalRefNum = booking.refNum
    setBookingOb(booking);
    setIsEdit(true);
  };

  useInterval(async () => {
    const nowish = new Date();
    nowish.setDate(nowish.getDate() - 1)
    const future = new Date();
    future.setMonth(nowish.getMonth() + 3);
    fetchCalendarData(
      { region: system, startTime: nowish, endTime: future },
      "/get-bookings"
    );
  }, 30000);
  useEffect(() => {
    if (deleteBookingRes.isLoading === false && deleteBookingRes.data) {
      handleDelModalClose();
    }
  }, [deleteBookingRes.isLoading, deleteBookingRes.data])

  useEffect(() => {
    const nowish = new Date();
    nowish.setDate(nowish.getDate() - 1)
    const future = new Date();
    future.setMonth(nowish.getMonth() + 3);
    fetchCalendarData(
      { region: system, startTime: nowish, endTime: future },
      "/get-bookings"
    );
  }, [
    system,
    fetchCalendarData,
    deleteBookingRes.data,
    bookingResponse.data,
    updateBookingResponse.data,
  ]);

  return (
    <Card
      variant="outlined"
      sx={{ borderColor: "primary.main", height: 600, overflow: "auto" }}
    >
      <Modal open={deleteModalOpen} onClose={handleDelModalClose}>
        <Box
          container
          display="flex"
          justifyContent="center"
          sx={{
            backgroundColor: "#111111",
            p: 5,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Stack 
            spacing={2}
            sx={{width: 400}}
            useFlexGap
            justifyContent="center"
            alignItems="center"
          >
            {
              deleteBookingRes.isLoading
                ? <Typography color="primary.warning">
                    Deleting booking: {modalRefNum}
                  </Typography>
                : <Typography color="primary.warning">
                    Confirm delete of booking: {modalRefNum}
                  </Typography>
            }
            <ButtonGroup align="center">
              <Button variant="contained" onClick={handleDelModalClose} disabled={deleteBookingRes.isLoading}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => handleDeleteBooking(modalRefNum)}
                disabled={deleteBookingRes.isLoading}
              >
                Delete
              </Button>
            </ButtonGroup>
          </Stack>
        </Box>
      </Modal>
      <Grid container spacing={1} sx={{ p: 2 }}>
        {calendarMapper(
          calendarDataResponse,
          confirmDelete,
          handleEditBooking,
          isEdit
        )}
      </Grid>
    </Card>
  );
};

export default Schedule;
