import {Grid, IconButton} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TimeZoneMap from "../../static/fancyTZmap.png";

const WorldTimesPresenter = () => {

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Grid>
        <>
          <Grid item xs={2}>
            <Grid item xs={2}>
              <IconButton
                aria-label="info"
                size="small"
                sx={{ mt: 0 }}
                onClick={handleOpen}
              >
                <InfoIcon />
              </IconButton>
            </Grid> 
            <Modal open={open} onClose={handleClose} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Box
                  component="img"sx={{width: "80%",
                  maxWidth:700,
                  maxHeight: "80vh",
                }}
                alt="time zone map"src={TimeZoneMap}
              />
            </Modal>
          </Grid>
        </>
    </Grid>
  );
};

export default WorldTimesPresenter;
